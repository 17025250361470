<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Home',
})
</script>
<template>
    <div class="body">
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <b>[</b><b>惠隽需求管理系统</b><b>] 用户服务条款</b>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <span>生效日期：</span>{{ d }}
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <span>欢迎使用</span> [<b>惠隽需求管理系统</b>]（以下简称 "我们" 或
            "系统"）。在使用我们的服务之前，请仔细阅读并理解以下服务条款。通过使用我们的服务，您同意遵守以下规定。如果您不同意这些服务条款，请停止使用我们的服务。
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <b>1. 服务的提供</b>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            1.1
            <b>注册与账户：</b>&nbsp;<span>在使用我们的服务之前，您需要创建一个账户。您必须提供准确、完整和最新的个人信息，并及时更新这些信息。您对您的账户负有保密责任，所有通过您的账户进行的活动都将被视为由您本人进行。</span>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            1.2 <b>合规性：</b>&nbsp;<span>您同意在使用我们的服务时遵守所有适用的法律法规，并保证不会违反任何第三方的权利。</span>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            1.3 <b>服务变更：</b>&nbsp;<span>我们保留随时更改或终止部分或全部服务的权利，而无需提前通知。我们会尽力通知您服务变更或终止的情况。</span>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <b>2. 用户行为规范</b>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            2.1 <b>禁止行为：</b>&nbsp;<span>在使用我们的服务时，您不得从事以下行为：</span>
        </p>
        <p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>侵犯他人的知识产权；</span>
        </p>
        <p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>发布虚假、欺诈、淫秽或攻击性信息；</span>
        </p>
        <p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>干扰或破坏我们的服务或与之相关的服务器和网络；</span>
        </p>
        <p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>违反适用的法律法规。</span>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            2.2 <b>内容审核：</b>&nbsp;<span>我们有权审核、删除或拒绝发布任何违反服务条款的内容。我们不对用户生成的内容负责。</span>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <b><span>3</span></b><b>. 免责声明</b>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <span>3</span>.1 <b>服务提供的方式：</b>&nbsp;<span>我们以</span> "现状" 提供服务，不对服务的适用性、可靠性、准确性、完整性和及时性作任何明示或暗示的担保。
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <span>3</span>.2 <b>责任限制：</b>&nbsp;<span>在适用法律允许的范围内，我们对因使用或无法使用我们的服务而导致的任何损失不承担任何责任。</span>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <b><span>4</span></b><b>. 隐私政策</b>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            您同意并理解我们的隐私政策，该政策详细说明了我们收集、使用和处理个人信息的方式。
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <b><span>5</span></b><b>. 终止</b>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            我们保留在任何时候终止或暂停您的账户和访问我们的服务的权利，如果我们合理地相信您违反了这些服务条款。
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <b><span>6</span></b><b>. 变更通知</b>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            我们保留随时更改这些服务条款的权利。我们将通过适当的方式通知您变更。您在变更后继续使用我们的服务将被视为对变更的接受。
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <b><span>7</span></b><b>. 联系我们</b>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            如果您有任何问题、疑虑或投诉，请联系我们：
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            [<span>Service@shhj-electric.cn</span>]
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <span>感谢您使用</span> [惠隽需求管理系统]！
        </p>
        <p class="MsoNormal">
            <br />
        </p>
        <img class="sy" :src="sy" width="160">
    </div>
</template>

<script lang="ts" setup name="Home">
import { ref } from 'vue'
import dayjs from 'dayjs'
import sy from '@/assets/sy.png';

const d = ref(dayjs().format('YYYY-MM-DD'))
</script>

<style scoped lang="less">
.body {
    width: 900px;
    margin: 0 auto;
    position: relative;
}

.sy {
    position: absolute;
    left: 20px;
    bottom: 50px;
}
</style>
