<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserMenu',
})
</script>
<template>
    <a-modal ref="modalRef" v-model:open="isedit" title="编辑" @ok="handleOk">
        <a-input v-model:value="editVal" />
    </a-modal>
    <div class="user-wrapper">
        <div class="content-box">
            <!-- <a href="https://github.com/bailihuiyue/ant-design-pro-vue3/blob/main/README.md" target="_blank">
                <span class="action">
                    <QuestionCircleOutlined />
                </span>
            </a> -->
            <!-- <notice-icon v-if="appType === 'C'" class="action" /> -->
            <a-dropdown>
                <span class="action ant-dropdown-link user-dropdown-menu">
                    <a-avatar>
                        <template #icon>
                            <UserOutlined />
                        </template>
                    </a-avatar>
                </span>
                <template #overlay>
                    <a-menu class="user-dropdown-menu-wrapper">
                        <div class="user-dropdown-box">
                            <div class="item">
                                <div>账号:</div>
                                <div>{{ UserInfo.username }}</div>
                            </div>
                            <div class="item">
                                <div>公司名: </div>
                                <div>
                                    <a-button @click="openEdit" type="link">
                                        {{ user_info.name }}
                                    </a-button>
                                </div>
                            </div>
                            <div class="item">
                                <div>星级:</div>
                                <div>{{ '⭐'.repeat(user_info.level) }}</div>
                            </div>
                            <div class="manage">
                                <div class="avatar">
                                    <a-avatar :size="40" :src="Kf">
                                    </a-avatar>
                                </div>
                                <div>
                                    <div class="item">
                                        <div>客服: </div>
                                        <div>{{ user_info.manage }}</div>
                                    </div>
                                    <div class="item">
                                        <div>电话: </div>
                                        <div>{{ user_info.phone }}</div>
                                    </div>
                                    <div class="item">
                                        <div>邮箱: </div>
                                        <div>{{ user_info.email }}</div>
                                    </div>
                                    <div class="item">
                                        <div>微信：</div>
                                        <div>
                                            <img :src="'http://xj.asap-get.com/upload/' + user_info.ercode" width="128px"
                                                alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a-button type="link" @click="handleLogout" :icon="h(LogoutOutlined)">
                            {{ $t('tools.userMenu.logout') }}
                        </a-button>
                        <!-- <a-menu-item key="0">
                            <router-link :to="{ name: 'center' }">
                                <a-icon type="user" />
                                <span>个人中心</span>
                            </router-link>
                        </a-menu-item>

                        <a-menu-item key="4" @click="showSystemSetting">
                            <a>
                                <SettingOutlined />
                                <span>{{ $t('tools.userMenu.systemConfig') }}</span>
                            </a>
                        </a-menu-item>
                        <a-menu-divider /> -->
                    </a-menu>

                </template>
            </a-dropdown>
            <span v-if="appType === 'A'" style="overflow: hidden;display: inline-block;">
                <SelectLang :class="theme" class="action" />
            </span>
        </div>
    </div>
</template>

<script lang="ts" setup name="UserMenu">
import NoticeIcon from '@/components/NoticeIcon/index.vue'
import { UserOutlined } from '@ant-design/icons-vue';
import { logout, getManage } from '@/views/user/service'
import { USER_INFO } from '@/store/mutation-types'
import { Modal } from 'ant-design-vue'
import Kf from '@/assets/kf.jpg';
import * as api from '@/views/user/service'


import {
    QuestionCircleOutlined,
    CheckOutlined,
    LogoutOutlined,
    LockOutlined
} from '@ant-design/icons-vue'
import { useI18n } from 'vue-i18n'
import { systemConfig } from '@/store/reactiveState'
import SelectLang from '@/components/SelectLang'
import ls from '@/utils/Storage'
import { useRouter } from 'vue-router'
import { clearUserInfo } from '@/utils/util'
import { onMounted, ref, h } from 'vue';


const appType = import.meta.env.VITE_APP_TYPE

const isedit = ref(false)
const editVal = ref('')

const props = defineProps(['theme'])
const { t } = useI18n()
const router = useRouter()
const UserInfo = ls.get(USER_INFO)
const handleLogout = () => {
    logout().then((res) => {
        clearUserInfo()
        router.push({ path: '/user/login' })
    })
    // Modal.confirm({
    //     title: t('tools.userMenu.tip'),
    //     content: t('tools.userMenu.checkLogout'),
    //     onOk: () => {

    //     },
    //     onCancel() { }
    // })
}
const showSystemSetting = () => {
    systemConfig.commit('SET_SETTING_DRAWER', true)
}

const user_info = ref({
    "username": "",
    "created_at": "",
    "name": "",
    "level": 3,
    "manage_id": 1,
    "manage": "Administrator",
    "phone": null,
    "email": null,
    "ercode": ''
})

onMounted(async () => {
    user_info.value = await getManage()
    // showSystemSetting()
})

const handleOk = async () => {
    // console.log('handleOk', editVal.value);
    await api.editCompany({ name: editVal.value })
    isedit.value = false
    user_info.value.name = editVal.value
}

const openEdit = () => {
    editVal.value = user_info.value.name
    isedit.value = true
}

</script>
<style lang="less">
.user-dropdown-menu-wrapper {
    .ant-dropdown-menu-item {
        width: 100% !important;
    }
}

.user-dropdown-box {
    max-width: 320px;
    background-color: #fff;
    border-radius: 4px;
    padding: 15px;

    .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;

        div:first-child {
            color: #888;
            font-weight: bold;
        }

        div:last-child {
            color: #333;
        }
    }

    .manage {
        display: flex;
        // align-items: center;
        border-top: 1px solid #ddd;
        padding-top: 16px;
    }

    .avatar {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        font-size: 14px;
    }
}
</style>
