
const lang = {
    型号: '型号',
    日期: '日期',
    查询: '查询',
    重置: '重置',
    导入: '导入',
    品牌: '品牌',
    新建: '新建',
    需求数量: '需求数量',
    库存数量: '库存数量',
    是否有货: '是否有货',
    需求: '需求',
    数量: '数量',
    提交: '提交',
    skillAdmin: '技能与爱好管理',
    needSkills: '请选择您的技能!',
    gongsi: '上海惠隽实业有限公司',
    包装量: '包装量',
    未税单价: '未税单价',
    "货期": '货期(天)',
    报价日期: '报价日期',
    模板: '模板',
    品牌字典: '品牌字典',
    发布人: '发布人',
    时间: '时间',
    导出: '导出',
    可出数量: '可出数量',
    批次: '批次'
}

export default lang
