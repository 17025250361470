import baseService from '@/utils/http/axios'
import { baseURL } from '@/utils/util'
import { API } from './types'

export const userLogin: API._Login = data => {
    const api = baseURL + `auth/login`
    return baseService.post(api, data)
}

export const userRegister: API._Login = async data => {
    const api = baseURL + `auth/register`
    return await baseService.post(api, data)
}


export const getSmsCaptcha: API._GetSmsCaptcha = data => {
    const api = baseURL + `account/sms`
    return baseService.post(api, data)
}

export const get2step: API._Get2Step = () => {
    const api = baseURL + `auth/2step-code`
    return baseService.get(api)
}

export const logout = () => {
    // const api = baseURL + `auth/logout`
    // return baseService.get(api)
    return new Promise<any>((resolve, reject) => {
        resolve(true)
    })
}

export const getManage: () => Promise<any> = () => {
    const api = baseURL + `user/getManage`
    return baseService.get(api)
}

export const subaccount = (page = 1, data) => {
    const api = baseURL + `user/subaccount`
    return baseService.get(api, { params: { page, ...data } })
}

export const subUpsert = (data) => {
    const api = baseURL + `user/subUpsert`
    return baseService.post(api, data)
}

export const editCompany = (data) => {
    const api = baseURL + `user/editCompany`
    return baseService.post(api, data)
}

export const getBrand: () => Promise<any> = () => {
    const api = baseURL + `demand/brand`
    return baseService.get(api)
}

export const demandCreate = (data) => {
    const api = baseURL + `demand/create`
    return baseService.post(api, data)
}

export const demand = (page = 1, data) => {
    const api = baseURL + `demand/list`
    return baseService.get(api, { params: { page, ...data } })
}

export const demandRead = (id) => {
    const api = baseURL + `demand/read`
    return baseService.post(api, { id })
}

export const storeCreate = (data) => {
    const api = baseURL + `store/create`
    return baseService.post(api, data)
}

export const storeEdit = (data) => {
    const api = baseURL + `store/edit`
    return baseService.post(api, data)
}

export const store = (page = 1, data) => {
    const api = baseURL + `store/list`
    return baseService.get(api, { params: { page, ...data } })
}

export const getmatch = (page = 1, data) => {
    const api = baseURL + `store/getmatch`
    return baseService.get(api, { params: { page, ...data } })
}

export const getmatchInfo = (data) => {
    const api = baseURL + `store/getmatchInfo`
    return baseService.get(api, { params: data })
}

export const addOffer = (data) => {
    const api = baseURL + `store/addOffer`
    return baseService.post(api, data)
}


export interface States {
    rememberMe: boolean;
    username: string;
    password: string;
    mobile: string;
    captcha: string;
}
