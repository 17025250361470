<template>
    <a-modal v-model:open="open" style="width: 800px;" :footer="null" :title="$t('demand.gongsi')">
        <!-- 上海惠隽实业有限公司 -->
        <a-table :dataSource="dataSource" :columns="[
            // { title: '供应商', dataIndex: 'supplier_id', key: 'supplier_id' },
            { title: t('demand.可出数量'), dataIndex: 'num', key: 'num' },
            { title: t('demand.包装量'), dataIndex: 'pack', key: 'pack' },
            { title: t('demand.未税单价'), dataIndex: 'price', key: 'price' },
            { title: t('demand.货期'), dataIndex: 'delivery', key: 'delivery' },
            { title: t('demand.批次'), dataIndex: 'batch', key: 'batch' },
            // { title: '备注', dataIndex: 'note', key: 'note' },
            { title: t('demand.报价日期'), dataIndex: 'updated_at', key: 'updated_at' },
        ]" />

    </a-modal>
</template>
<script lang="ts" setup>
import { ref, reactive, toRaw } from 'vue';
import * as api from '@/views/user/service'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

let callback = null as any
const open = ref<boolean>(false);

const dataSource = ref([])

const show = async (db, cb) => {
    open.value = true;
    dataSource.value = db
    callback = cb
};

defineExpose({ show })
</script>
