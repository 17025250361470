<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Logo',
})
</script>
<template>
    <div class="logo">
        <router-link :to="{ path: '/' }">
            <SvgIcon name="logo" />
            <h1 v-if="showTitle">{{ title }}</h1>
        </router-link>
    </div>
</template>

<script lang="ts" setup name="Logo">
import SvgIcon from '@/components/SvgIcon/index.vue'

const props = defineProps({
    title: {
        type: String,
        default: '惠隽',
        required: false,
    },
    showTitle: {
        type: Boolean,
        default: true,
        required: false,
    },
})
</script>
