<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Home',
})
</script>
<template>
    <div class="body">
        <p>
            <br />
        </p>
        <p class="MsoNormal">
            <br />
        </p>
        <h1 style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <span>用户隐私政策</span>
        </h1>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <span>生效日期：</span>{{ d }}
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <span>欢迎使用</span> [惠隽需求管理系统]（以下简称 "我们" 或 "系统"）。我们致力于保护用户的隐私，并采取一系列措施来保护您的个人信息。请您在使用我们的服务之前仔细阅读本隐私政策，了解我们的信息处理实践。
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            1. 收集的信息
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            在您使用我们的服务时，我们可能会收集以下类型的信息：
        </p>
        <p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>个人信息：</span>&nbsp;例如，您的姓名、联系方式、地址等，用于识别您的身份。
        </p>
        <p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>账户信息：</span>&nbsp;包括您在注册或使用我们的服务时创建的账户信息。
        </p>
        <p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>交易信息：</span>&nbsp;当您使用我们的支付服务时，我们可能会收集与交易相关的信息。
        </p>
        <p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>设备信息：</span>&nbsp;我们可能会收集与您使用的设备相关的信息，如设备型号、操作系统等。
        </p>
        <p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>日志信息：</span>&nbsp;我们会自动收集关于您使用我们服务的日志信息，包括 IP 地址、访问时间等。
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            2. 信息的使用
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            我们可能会将您的个人信息仅用于以下目的：
        </p>
        <p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>提供、维护和改进我们的服务。</span>
        </p>
        <p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>处理交易和提供客户支持。</span>
        </p>
        <p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>向您发送有关我们服务的通知、更新和推广信息。</span>
        </p>
        <p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;">
            ·&nbsp;<span>遵守法律法规的要求。</span>
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            3. 信息的保护
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            我们采取了安全措施来保护您的个人信息免遭未经授权的访问、使用或泄露。我们会定期审查我们的安全措施，并不断改进以确保您的信息得到妥善保护。
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            4. Cookie和类似技术
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <span>我们可能使用</span>Cookie和类似的技术来提高用户体验，分析流量和进行广告定位。您可以通过浏览器设置选择接受或拒绝Cookie。
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            5. 更新和联系我们
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            我们可能会不定期更新本隐私政策，以反映我们服务和法规的变化。在进行重大更改时，我们将通过适当的方式通知您。
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            如果您对本隐私政策或我们的信息处理实践有任何问题或疑虑，请联系我们：
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            [<span>Service@shhj-electric.cn</span>]
        </p>
        <p class="p" style="margin-left:0.0000pt;text-indent:0.0000pt;background:#FFFFFF;">
            <span>感谢您使用</span> [惠隽需求管理系统]！
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <p>
            <br />
        </p>
        <p>
            <br />
        </p>
        <img class="sy" :src="sy" width="160">
    </div>
</template>

<script lang="ts" setup name="Home">
import { ref } from 'vue'
import dayjs from 'dayjs'
import sy from '@/assets/sy.png';

const d = ref(dayjs().format('YYYY-MM-DD'))
</script>

<style scoped lang="less">
.body {
    width: 900px;
    margin: 0 auto;
    position: relative;
}

.sy {
    position: absolute;
    left: 20px;
    bottom: 50px;
}
</style>
