<template>
    <a-modal v-model:open="open" style="width: 800px;" :footer="null" title="上海惠隽实业有限公司">
        <!-- 上海惠隽实业有限公司 -->
        <a-table :dataSource="dataSource" :columns="[
            // { title: '供应商', dataIndex: 'supplier_id', key: 'supplier_id' },
            { title: '库存数量', dataIndex: 'num', key: 'num' },
            { title: '包装量', dataIndex: 'pack', key: 'pack' },
            { title: '未税单价', dataIndex: 'price', key: 'price' },
            { title: '货期(天)', dataIndex: 'delivery', key: 'delivery' },
            { title: '备注', dataIndex: 'note', key: 'note' },
            { title: '报价日期', dataIndex: 'updated_at', key: 'updated_at' },
        ]" />

    </a-modal>
</template>
<script lang="ts" setup>
import { ref, reactive, toRaw } from 'vue';
import * as api from '@/views/user/service'

let callback = null as any
const open = ref<boolean>(false);

const dataSource = ref([])

const show = async (db, cb) => {
    open.value = true;
    dataSource.value = db
    callback = cb
};

defineExpose({ show })
</script>
