<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TableList',
})
</script>
<template>
    <div>
        <a-form>
            <a-row :gutter="[16, 16]">
                <a-col :md="6" :sm="24">
                    <a-form-item label="型号">
                        <a-input v-model:value="queryParam.model" placeholder="" />
                    </a-form-item>
                </a-col>
                <template v-if="advanced">
                    <a-col :md="6" :sm="24">
                        <a-form-item label="日期">
                            <a-range-picker v-model:value="queryParam.date" style="width: 100%" />
                        </a-form-item>
                    </a-col>

                    <!-- <a-col :md="8" :sm="24">
                        <a-form-item label="使用状态">
                            <a-select placeholder="请选择" default-value="0">
                                <a-select-option value="0">全部</a-select-option>
                                <a-select-option value="1">关闭</a-select-option>
                                <a-select-option value="2">运行中</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col> -->
                </template>
                <a-col :md="4" :sm="24">
                    <span class="table-page-search-submitButtons" :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}
                ">
                        <a-button type="primary" :icon="h(SearchOutlined)" @click="onSearch">查询</a-button>
                        <a-button style="margin-left: 8px" @click="resetQueryParam">重置</a-button>
                        <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                        {{ advanced ? "收起" : "展开" }}
                        <a-icon :type="advanced ? 'up' : 'down'" />
                    </a> -->
                    </span>
                </a-col>
            </a-row>
        </a-form>
        <br />
        <div class="table-operator" style="display: flex;justify-content: flex-end; padding: 5px;gap:10px">
            <a href="/template-2.xlsx">
                <a-button type="link"> 模板</a-button>
            </a>
            <a-tooltip>

                <template #title>品牌需遵守品牌字典，大小写与系统保持一致</template>
                <a-upload v-model:file-list="fileList" name="file" accept=".xlsx" :show-upload-list="false"
                    :action="`${baseURL}store/upload`" :headers="headers" @change="handleChange">
                    <a-button>
                        <upload-outlined></upload-outlined>
                        导入
                    </a-button>
                </a-upload>
            </a-tooltip>
            <a-button type="primary" @click="handleAdd" :icon="h(PlusCircleOutlined)"> 新建</a-button>
            <a-dropdown v-if="selectedRowKeys.length > 0">

                <template #overlay>
                    <a-menu>
                        <a-menu-item key="1">删除</a-menu-item>
                    </a-menu>
                </template>
                <a-button style="margin-left: 8px">
                    批量操作
                </a-button>
            </a-dropdown>
        </div>
        <br />
        <a-table :dataSource="dataSource" :pagination="pagination" @change="handleTableChange" :loading="loading"
            :columns="[
                { title: '型号', dataIndex: 'model', key: 'model' },
                { title: '数量', dataIndex: 'num', key: 'num' },
                { title: '单价', dataIndex: 'price', key: 'price' },
                { title: '更新时间', dataIndex: 'updated_at', key: 'updated_at' },
                { title: '操作', key: 'action' },
            ]">

            <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'brand'">
                    <a-tag color="processing">{{ record.brand.name }}</a-tag>
                </template>

                <template v-else-if="column.key === 'updated_at'">
                    <p>{{ record.updated_at }}</p>
                    <a-tag v-if="dayjs(record.updated_at).isBefore(dayjs().subtract(30, 'day'))" color="pink">
                        您已长期未更新库存数据，将影响商机质量
                    </a-tag>
                </template>

                <template v-else-if="column.key === 'action'">
                    <a-button type="primary" @click="handleEdit(record)"> 更新 </a-button>
                </template>
            </template>
        </a-table>
        <Box ref="boxref"></Box>
    </div>
</template>

<script lang="ts" setup name="TableList">
import { defineComponent, ref, reactive, computed, h } from "vue";
import { message } from 'ant-design-vue';
import { SearchOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons-vue';
import * as api from '@/views/user/service'
import dayjs from "dayjs";
import Box from "./Box.vue";
import ls from '@/utils/Storage'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { baseURL } from '@/utils/util'

interface QueryParam {
    model?: string;
    status?: number;
    date?: dayjs.Dayjs;
}

const emit = defineEmits(['onEdit'])

// 高级搜索 展开/关闭
const advanced = ref(true);
const boxref = ref()


// 查询参数
const queryParam = reactive(<QueryParam>{
    model: '',
    status: undefined,
    date: undefined //dayjs(new Date()),
});

const pagination = reactive({
    total: 0,
    current: 1,
    pageSize: 10,
});
const loading = ref(false)
const dataSource = ref()

const handleTableChange = (pag: { pageSize: number; current: number },
    filters: any,
    sorter: any,
) => {
    loadData(pag.current, queryParam)
};

const selectedRowKeys = ref([]);
const selectedRows = ref([]);

const optionAlertShow = ref<boolean>(false);

// 加载数据方法 必须为 Promise 对象
const loadData = async (page, parameter = {}) => {
    // console.log("loadData.parameter", parameter);
    loading.value = true
    try {
        const dataa = await api.store(page, parameter) as any
        dataSource.value = dataa.data
        pagination.total = dataa.total
        pagination.current = dataa.current_page
    } catch (error) {

    }
    loading.value = false

};
loadData(1)
const resetQueryParam = () => {
    queryParam.model = ''
    queryParam.status = undefined
    queryParam.date = undefined
};

const onSearch = () => {
    loadData(1, queryParam)
};

const onSelectChange = function (rowKeys, rows) {
    selectedRowKeys.value = rowKeys;
    selectedRows.value = rows;
};
const toggleAdvanced = function () {
    advanced.value = !advanced.value;
};

const options = reactive({
    alert: {
        show: true,
        clear: () => {
            selectedRowKeys.value = [];
        },
    },
    rowSelection: {
        selectedRowKeys: selectedRowKeys.value,
        onChange: onSelectChange,
    },
});


const handleAdd = function () {
    emit("onEdit", '');
};
const handleEdit = function (record) {
    console.log(record);
    emit("onEdit", record);
};

const resetSearchForm = function () {
    Object.assign(queryParam, {
        date: dayjs(new Date()),
    });
};

const openBox = (d) => {
    boxref.value.show(d)
}

const fileList = ref([]);
const handleChange = (info: any) => {
    console.log(info);

    if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
        message.success(`${info.file.name} 导入成功`); // import successfully
        loadData(1)
    } else if (info.file.status === 'error') {
        message.error(`${info.file.name} 导入失败`); // import failed
    }
};

const token = ls.get(ACCESS_TOKEN)
const headers = { token };

const download = () => {
    const p = new URLSearchParams(queryParam).toString();
    window.open(`${baseURL}store/download?${p}&token=${token}`)
}

defineExpose({ loadData })

</script>
