<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UserLayout',
})
</script>
<template>
    <div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
        <div class="container">
            <div v-if="appType === 'A'" class="user-layout-lang">
                <select-lang class="select-lang-trigger" />
            </div>
            <div class="user-layout-content">
                <div class="top">
                    <div class="header">
                        <a href="/">
                            <img src="~@/assets/icons/logo2.png" class="logo" alt="logo" />
                            <span class="title">惠隽</span>
                        </a>
                    </div>
                    <div class="desc">{{ $t(`layouts.userLayout.title${appType}`) }}</div>
                </div>

                <router-view />

                <div class="footer">
                    <!-- <div class="links">
                        <a href="_self">帮助</a>
                        <a href="_self">隐私</a>
                        <a href="_self">条款</a>
                    </div> -->
                    <div class="copyright">Copyright &copy;&nbsp;2023 Ones</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup name="UserLayout">
import SelectLang from '@/components/SelectLang';
import { isMobile } from '@/utils/device';
import { onMounted, onBeforeUnmount } from 'vue';

const appType = import.meta.env.VITE_APP_TYPE

onMounted(() => {
    document.body.classList.add('userLayout');
});
onBeforeUnmount(() => {
    document.body.classList.remove('userLayout');
});
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
    height: 100%;

    &.mobile {
        .container {
            .main {
                max-width: 368px;
                width: 98% !important;
            }
        }
    }

    .container {
        width: 100%;
        min-height: 100%;
        background: #f0f2f5 url(../assets/login_background.png) no-repeat 50%;
        background-size: 100%;
        //padding: 50px 0 84px;
        position: relative;

        .user-layout-lang {
            width: 100%;
            height: 40px;
            line-height: 44px;
            text-align: right;

            ::v-deep(.select-lang-trigger) {
                cursor: pointer;
                padding: 12px;
                margin-right: 16px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                vertical-align: middle;
            }
        }

        ::v-deep(.ant-form) {
            padding: 10px;
            background: #ffffffcc;
            border-radius: 5px;
        }

        .user-layout-content {
            padding: 32px 0 24px;

            .top {
                text-align: center;

                .header {
                    height: 44px;
                    line-height: 44px;

                    .badge {
                        position: absolute;
                        display: inline-block;
                        line-height: 1;
                        vertical-align: middle;
                        margin-left: -12px;
                        margin-top: -10px;
                        opacity: 0.8;
                    }

                    .logo {
                        height: 44px;
                        vertical-align: top;
                        margin-right: 16px;
                        border-style: none;
                    }

                    .title {
                        font-size: 33px;
                        color: rgba(250, 250, 250, 0.85);
                        font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
                        font-weight: 600;
                        position: relative;
                        top: 2px;
                    }
                }

                .desc {
                    font-size: 14px;
                    color: rgba(250, 250, 250, 0.45);
                    margin-top: 12px;
                    margin-bottom: 40px;
                }
            }

            .main {
                min-width: 260px;
                width: 368px;
                margin: 0 auto;
            }

            .footer {
                // position: absolute;
                width: 100%;
                bottom: 0;
                padding: 0 16px;
                margin: 48px 0 24px;
                text-align: center;

                .links {
                    margin-bottom: 8px;
                    font-size: 14px;

                    a {
                        color: rgba(250, 250, 250, 0.45);
                        transition: all 0.3s;

                        &:not(:last-child) {
                            margin-right: 40px;
                        }
                    }
                }

                .copyright {
                    color: rgba(250, 250, 250, 0.45);
                    font-size: 14px;
                }
            }
        }

        a {
            text-decoration: none;
        }
    }
}
</style>
