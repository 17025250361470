const lang = {
    型号: 'Model',
    日期: 'Date',
    查询: 'Query',
    重置: 'Reset',
    导入: 'Import',
    品牌: 'Brand',
    新建: 'New',
    需求数量: 'Demand quantity',
    库存数量: 'Available QTY',
    是否有货: 'Has Goods',
    需求: 'Demand',
    数量: 'Quantity',
    提交: 'Submit',
    skillAdmin: '技能与爱好管理',
    needSkills: '请选择您的技能!',
    gongsi: 'Shanghai HuiJun Industrial Ltd',
    包装量: 'SPQ',
    未税单价: 'EXW U/P(CNY)',
    "货期": 'LT(Days)',
    报价日期: 'Quote Date',
    模板: 'template',
    品牌字典: 'Brand dictionary',
    导出: 'export',
    发布人: 'Posted By ',
    时间: 'Time',
    可出数量: 'Available QTY',
    批次: 'Batch'
}

export default lang
